import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/[[locale=locale]]/about-us": [7,[2]],
		"/[[locale=locale]]/admin": [8,[2]],
		"/[[locale=locale]]/allianz": [~9,[2]],
		"/api/payment-intent/validate": [~32],
		"/[[locale=locale]]/axa-policy-check": [~11,[2]],
		"/[[locale=locale]]/axa": [~10,[2]],
		"/[[locale=locale]]/become-a-partner": [12,[2]],
		"/[[locale=locale]]/confirmation/[cart_id]": [~13,[2]],
		"/[[locale=locale]]/embassies-consulates": [14,[2]],
		"/[[locale=locale]]/extend/[cart_id]": [~15,[2]],
		"/[[locale=locale]]/extend/[cart_id]/expired": [16,[2]],
		"/[[locale=locale]]/extend/[cart_id]/link-expired": [17,[2]],
		"/[[locale=locale]]/extend/[cart_id]/payment": [~18,[2]],
		"/[[locale=locale]]/healix-policy-check": [~19,[2]],
		"/[[locale=locale]]/login": [20,[2]],
		"/[[locale=locale]]/mutuaide-policy-check": [~22,[2]],
		"/[[locale=locale]]/mutuaide": [~21,[2]],
		"/[[locale=locale]]/payment/[cart_id]": [~23,[2]],
		"/[[locale=locale]]/quotation/[cart_id]": [~24,[2]],
		"/[[locale=locale]]/redirect": [25,[2]],
		"/[[locale=locale]]/schengen-travel-insurance": [~26,[2]],
		"/test": [33],
		"/travel-guide": [~34,[3]],
		"/travel-guide/author/[slug]": [~36,[3]],
		"/travel-guide/category/[slug]": [~37,[3]],
		"/travel-guide/manage": [38,[3]],
		"/travel-guide/manage/preview": [39,[3,4]],
		"/travel-guide/manage/preview/page/[slug]": [~40,[3,4]],
		"/travel-guide/manage/preview/post/[slug]": [~41,[3,4]],
		"/travel-guide/search": [42,[3]],
		"/travel-guide/tags/[slug]": [~43,[3]],
		"/travel-guide/[slug]": [~35,[3]],
		"/[[locale=locale]]/travel-insurance-australia": [~27,[2]],
		"/[[locale=locale]]/travel-insurance-uk": [~28,[2]],
		"/[[locale=locale]]/travel-medical-insurance": [~29,[2]],
		"/[[locale=locale]]/visitors-insurance": [~30,[2]],
		"/[[locale=locale]]/your-details/[cart_id]": [~31,[2]],
		"/zendesk": [44],
		"/[[locale=locale]]": [~5,[2]],
		"/[[locale=locale]]/[slug]": [~6,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';