import { locales } from '$lib/translations';

/** @type {import('@sveltejs/kit').ParamMatcher} */
export function match(param) {
  let definedLocales = locales.get().map((l) => l.toLowerCase());
  //replace fil to tl
  definedLocales = definedLocales.map((l) => l === 'fil' ? 'tl' : l);
  const paths = [...definedLocales, ''];
  const slashPaths = paths.map((l) => `${l}/`);

  return [...paths, ...slashPaths].includes(param);
}