import * as Sentry from "@sentry/sveltekit";

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
  dsn: "https://0a428152f23a371c265f6fc0421cf012@o4506914118500352.ingest.us.sentry.io/4506914119876608",
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  integrations: [Sentry.replayIntegration()]
});

export const handleError = Sentry.handleErrorWithSentry();
